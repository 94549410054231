* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

a {
  text-decoration: none;
}

/* MemberShip Start */
.membership_container {
  background-color: #f3f4f8;
}

.plan__name {
  color: #000;
  font-size: 25px;
}
/* MemberShip End */
/* Product Slider Start */
.product__slider_container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
/* Product Slider End */
.swiper-pagination-bullet {
  background: #fff !important;
}
.login_root {
  background-image: url("https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register_root {
  background-image: url("https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper {
  z-index: 0 !important;
}
